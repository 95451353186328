import { FC, useEffect } from 'react'
import { useGetUserPermissionsAndProductsLazyQuery } from '@/generated/graphql'
import { useUserID } from '@/hooks/id_token_claims'
import { Permission, ProductType } from '@/generated/enums'
import {
  userPermissionsDataAtom,
  userRoleAtom,
  userPermissionsAtom,
  userProductsAtom,
} from '@/atoms/permissions'
import { useAtom, useSetAtom } from 'jotai'

const useUserPermissionsData = () => {
  const [userId] = useUserID()
  const [userData, setUserData] = useAtom(userPermissionsDataAtom)

  const [
    getUserPerms,
    { data: userPermsData, loading: userPermsLoading, error: userPermsError },
  ] = useGetUserPermissionsAndProductsLazyQuery({
    variables: {
      user_id: userId,
    },
  })

  useEffect(() => {
    if (userId) {
      getUserPerms()
    }
  }, [userId])

  useEffect(() => {
    if (userPermsData) {
      if (userPermsData?.users?.length > 0) {
        const user = userPermsData?.users?.[0]
        setUserData(user)
      }
    }
  }, [userPermsData])

  return userData
}

const parseRolePermissions = (permissions): Set<Permission> =>
  new Set<Permission>(
    permissions.map((permission) => {
      return `${permission.permission.resource}.${
        permission.permission.feature ? `${permission.permission.feature}.` : ''
      }${permission.permission.action}` as Permission
    }),
  )

const InitializePermissions: FC = () => {
  const userData = useUserPermissionsData()
  const setUserRole = useSetAtom(userRoleAtom)
  const setUserPermissions = useSetAtom(userPermissionsAtom)
  const setUserProducts = useSetAtom(userProductsAtom)

  useEffect(() => {
    if (userData) {
      setUserRole(userData?.role)
      setUserPermissions(parseRolePermissions(userData?.role?.role_permissions))
      setUserProducts(
        userData?.products?.map((product) => product.product as ProductType),
      )
    }
  }, [userData])

  return null
}

export const InitializeAtoms: FC = () => {
  return (
    <>
      <InitializePermissions />
    </>
  )
}
