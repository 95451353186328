export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function toSnakeCase(str: string) {
  return str.split(' ').join('_').toLowerCase()
}

export function toCustomSnakeCase(str: string) {
  return str
    .toLowerCase()
    .replace(/[\s\-/]+/g, '_')
    .replace(/[^\w_]/g, '')
}

export function replaceUnderscoresWithSpaces(str: string) {
  return str.split('_').join(' ')
}

export const cleanText = (text) => text.replace(/[\r\n\t]/g, '')

export function unSnakeCase(str: string) {
  if (str) {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  } else {
    return ''
  }
}

export function casingForDbFields(str: string) {
  return unSnakeCase(str).replace('Ip', 'IP')
}

export const getStringAfterFirstHyphen = (str) => {
  const parts = str.split('_')
  if (parts.length > 1) {
    return parts.slice(1).join('_')
  }
  return ''
}

export const constructDarkOwlEnum = (str) => {
  if (str === 'EMAIL') {
    return ['DARKOWL_EMAIL', 'INTELX_EMAIL']
  }

  return [`DARKOWL_${str}`]
}

// gets the text and surrounding 40 chars from an html body that surrounded by <em></em> tags
export function getContextAroundHighlightedContent(
  html: string,
  surroundingChars = 40,
) {
  // Regular expression to extract the content inside <em> tags
  const emRegex = /<em>(.*?)<\/em>/g
  let match

  // For each <em> tag
  while ((match = emRegex.exec(html)) !== null) {
    // Get the start and end indices for surrounding characters
    const start = Math.max(0, match.index - surroundingChars)
    const end = Math.min(html.length, emRegex.lastIndex + surroundingChars)

    return html.substring(start, end)
  }
}

const wordReplacements = {
  Ui: 'UI',
}

export function customWordReplacer(str) {
  for (const word in wordReplacements) {
    const replacement = wordReplacements[word]
    // Use a global RegExp to replace all instances of the word
    const regex = new RegExp(`\\b${word}\\b`, 'g')
    str = str.replace(regex, replacement)
  }
  return str
}

export function extractSubreddit(url) {
  const match = url.match(/\/r\/(\w+)\//)
  return match ? match[1] : null
}

export function extractDiscordIds(url) {
  const match = url.match(/\/channels\/(\d+)\/(\d+)\//)
  return match ? { serverId: match[1], channelId: match[2] } : null
}

export function getTruncatedText(text, maxTextLength) {
  return text?.length > maxTextLength ? text.substr(0, maxTextLength) + '...' : text
}

export const isValidCountryCode = (code: string): boolean => {
  return /^[A-Za-z]{2}$/.test(code)
}

export const isValidEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase())
}

export const formatSnakeStringWithCapitalization = (value: string) => {
  if (value === null || value === undefined) {
    return ''
  }

  return value
    .split(':')[0]
    .split('_')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}
