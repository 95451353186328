import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaExclamationCircle, FaTools, FaUser } from 'react-icons/fa'
import {
  FiBox,
  FiHome,
  FiTrendingUp,
  FiLayers,
  FiSettings,
  FiEye,
} from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaGavel } from 'react-icons/fa'
import Sidebar, { SidebarItem } from '../components/sidebar'
import { getOrgData, useIsEmployeeView } from '../hooks/id_token_claims'
import Login from '../pages/login'
import { DOPPEL_ERROR } from '../utils/style'
import { GiMoneyStack } from 'react-icons/gi'
import { getTabsForOrg } from '@/pages/stats'
import { ORGANIZATION_STATUSES_WITH_ANALYTICS } from '@/utils/analytics'
import { useAtomValue } from 'jotai'
import { userProductsAtom } from '@/atoms/permissions'
import { ProductType } from '@/generated/enums'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { isUserAuthorizedForProductType } from '@/utils/permissions'
import { getTabsForOrg as getTabsForOrgAlerts } from '@/pages/reports'

const getSideBarItems = (
  isEmployeeView,
  orgData?,
  userProducts?: ProductType[],
  isRbacEnabled?: boolean,
): SidebarItem[] => {
  const analyticsRoute =
    getTabsForOrg(isEmployeeView, orgData)?.[0]?.route || '/analytics/cred-theft'
  const alertsRoute =
    (orgData &&
      getTabsForOrgAlerts(orgData, userProducts, isRbacEnabled)?.[0]?.route) ??
    '/domains'
  return [
    {
      name: 'Alerts',
      icon: FiHome,
      href: alertsRoute,
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Assets',
      icon: FiBox,
      href: '/brands',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Analytics',
      icon: FiTrendingUp,
      href: analyticsRoute,
      isVisible: ORGANIZATION_STATUSES_WITH_ANALYTICS.includes(
        orgData?.organization_status,
      ),
      isInternal: false,
    },
    {
      name: 'PII',
      icon: FiEye,
      href: '/pii',
      isVisible:
        orgData?.is_pii_detection_enabled &&
        isUserAuthorizedForProductType(ProductType.PII, userProducts, isRbacEnabled),
      isInternal: false,
    },
    {
      name: 'Domain Purchasing',
      icon: GiMoneyStack,
      href: '/domain-purchasing',
      isVisible:
        orgData?.is_domain_purchasing_enabled &&
        isUserAuthorizedForProductType(
          ProductType.DOMAIN_PURCHASING,
          userProducts,
          isRbacEnabled,
        ),
      isInternal: false,
    },
    {
      name: 'Suspicious Emails',
      icon: MdOutlineEmail,
      href: '/suspicious-emails',
      isVisible:
        orgData?.is_suspicious_emails_enabled &&
        isUserAuthorizedForProductType(
          ProductType.SUSPICIOUS_EMAILS,
          userProducts,
          isRbacEnabled,
        ),
      isInternal: false,
    },
    {
      name: 'Organization',
      icon: FiLayers,
      href: '/users',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'App Settings',
      icon: FiSettings,
      href: '/app-settings',
      isVisible: true,
      isInternal: false,
    },
    {
      name: 'Admin',
      icon: FaUser,
      href: '/admin/alerts/domains',
      isVisible: isEmployeeView,
      isInternal: true,
    },
    {
      name: 'Enforcements',
      icon: FaGavel,
      href: '/enforcements/automated-takedowns',
      isVisible: isEmployeeView,
      isInternal: true,
    },
    {
      name: 'Detection Tooling',
      icon: FaTools,
      href: '/detection_tooling/sourcing_queries',
      isVisible: isEmployeeView,
      isInternal: true,
    },
  ]
}

const ERROR_DELAY = 2000

export default function Layout({ children, padding = 4 }) {
  const { isAuthenticated, isLoading } = useAuth0()
  const [isEmployeeView] = useIsEmployeeView()
  const [orgData] = getOrgData()
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')

  const [sidebarItems, setSidebarItems] = useState([
    ...getSideBarItems(isEmployeeView, orgData, userProducts, isRbacEnabled),
  ])

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!orgData) {
      const timeoutId = setTimeout(() => {
        setShowError(true)
      }, ERROR_DELAY)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [orgData])

  useEffect(() => {
    setSidebarItems([
      ...getSideBarItems(isEmployeeView, orgData, userProducts, isRbacEnabled),
    ])
  }, [isEmployeeView, orgData, userProducts])

  if (!isAuthenticated && !isLoading) {
    return <Login />
  }

  if (isLoading || !orgData) {
    const component = isLoading ? (
      <Spinner />
    ) : (
      <Flex alignItems={'center'}>
        {showError && (
          <>
            <FaExclamationCircle color={DOPPEL_ERROR} size={20} />

            <Text ml={2}>
              {'Email is not currently registered. Contact Doppel support for help.'}
            </Text>
          </>
        )}
      </Flex>
    )

    return (
      <Sidebar
        component={
          <Flex align="center" height="80vh" justify="center" width="100vw">
            {component}
          </Flex>
        }
        sidebarItems={sidebarItems}
      />
    )
  }

  const currentComponent = (
    <Flex justifyContent="flex-start" maxWidth="100%" padding={padding} width="100%">
      {children}
    </Flex>
  )
  return <Sidebar component={currentComponent} sidebarItems={sidebarItems} />
}
