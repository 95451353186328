export const DOPPEL_FONT_FAMILY_MONO = `'Inter', monospace`
export const DOPPEL_FONT_FAMILY_BODY = `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
export const DOPPEL_FONT_FAMILY_HEADING = `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`

export const FONT_SIZE_SMALL = '12px'
export const FONT_SIZE_MEDIUM = '14px'

export const DOPPEL_INTERNAL_PURPLE = '#806584'
export const DOPPEL_INTERNAL_PURPLE_SHADE = '#B78DB7'
export const DOPPEL_TERMINAL_BLUE = '#142349'
export const DOPPEL_TERMINAL_BLUE_SHADE = '#060D20'

export const DOPPEL_OFFICIAL_BLUE = '#0F52FF'

export const DOPPEL_CYBER_BLUE = '#336AEA'
export const DOPPEL_CYBER_BLUE_SHADE = '#3453B3'

export const DOPPEL_CLOUD_BLUE = '#3FAEFF'
export const DOPPEL_CLOUD_BLUE_SHADE = '#2F9EEE'
export const DOPPEL_TEAL_BLUE = '#449CDB'

export const DOPPEL_WHITE = '#EEEFFB'
export const DOPPEL_LIGHT_GREY = '#B1B1B1'
export const DOPPEL_GREY = '#E7E7E7'
export const DOPPEL_LINE_GREY = '#4B4C54'
export const DOPPEL_BORDER_GREY = '#727272'
export const DOPPEL_BUTTON_GREY = '#4F5055'
export const DOPPEL_BUTTON_GREY_SHADE = '#5F6167'
export const DOPPEL_CLICKABLE_GREY = '#edf2f6'
export const DOPPEL_CLICKABLE_GREY_SHADE = '#DDE2E7'
export const DOPPEL_HOVER = '#1A202C'
export const DOPPEL_PLACEHOLDER_GREY = '#9E9E9E'

export const DOPPEL_SECURE = '#727E9B'
export const DOPPEL_SECURE_SHADE = '#5C6989'

export const DOPPEL_BREACH_RED = '#D6524A'
export const DOPPEL_BREACH_RED_SHADE = '#A64540'

export const DOPPEL_FIREWALL_ORANGE = '#D18A38'

export const DOPPEL_YELLOW = '#FFBE3F'
export const DOPPEL_DIM_YELLOW = '#A98733'

export const DOPPEL_ENCRYPT_GREEN = '#229954'
export const DOPPEL_ENCRYPT_GREEN_SHADE = '#44EA73'
export const DOPPEL_SECONDARY_GREEN = '#44B163'

export const DOPPEL_BLACK = '#000000'
export const DOPPEL_DARK = '#181921'
export const DOPPEL_DARK_SECONDARY = '#20212D'
export const DOPPEL_DARK_CLICKABLE = '#272938'
export const DOPPEL_DARK_CLICKABLE_HOVER = '#2F3140'
export const DOPPEL_DARK_GREY = '#1E1E2A'

export const DELIST_COLOR = '#58FF87'
export const MENU_LIST_ZINDEX = 10
export const DOPPEL_SUCCESS = '#4d875d'
export const DOPPEL_ERROR = '#FF4E4E'
export const DOPPEL_LINK = '#93B3F2'

export const DOPPEL_BLANK_SHADE = '#FFFFFF1A' // 10% opacity filter
export const DOPPEL_TAG_OUTLINE = '#FEFEFFCC' // 80% opacity
export const DOPPEL_TEXT_WHITE = '#EEEFFB'
export const DOPPEL_TEXT_GREY = '#B3B5C2'
export const DOPPEL_TEXT_GREY_DIM = '#7E7F8D'
export const DOPPEL_TEXT_BLUE = '#0F52FF'
export const DOPPEL_TEXT_RED = '#E4544A'
export const DOPPEL_TEXT_INTERNAL_PURPLE_DARK = '#801384'
export const DOPPEL_TEXT_BLACK = '#000000'
export const DOPPEL_TEXT_DARK = '#181921'

export const DOPPEL_ACTIVE_SILVER = '#8292A2'
export const DOPPEL_INACTIVE_GREY = '#2E373F'

export const DOPPEL_DIVIDER = '#0000001A'

export const BUTTON_COLOR_PRIMARY = '#0F52FF'
export const BUTTON_COLOR_PRIMARY_SHADE = '#0F42AA'
export const BUTTON_COLOR_SECONDARY = '#B3B5C2'

export const DROPDOWN_OUTLINE = '#00000018'

export const DOPPEL_TAG_BACKGROUND_BLUE = '#0F52FF1A'
export const DOPPEL_TAG_WHITE = '#FEFEFF'
